<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="projectData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données du Projet</h4>
      <div class="alert-body">
       Pas de projet trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'projects-list' }">
          la liste des Projets
        </b-link>
        pour d'autres Projets.
      </div>
    </b-alert>

    <template v-if="projectData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="9" lg="8" md="7">
          <project-view-project-info-card :project-data="projectData" @stateUpdated="projectUpdated" />
        </b-col>
        <b-col cols="12" md="5" xl="3" lg="4">
          <project-view-project-plan-card @projectUpdated="projectUpdated = true" :project-data="projectData" />
        </b-col>
      </b-row>

      <!-- <invoice-list /> -->
      <expense-list v-if="$can('read_project_requests', 'Projets')" :projectId="projectId" :updated.sync="projectUpdated" @expenseUpdated="projectUpdated = true" />
    </template>
  </div>
</template>

<script>
/* eslint-disable */
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted, computed } from "@vue/composition-api";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import userStoreModule from '../userStoreModule'
import ProjectViewProjectInfoCard from "./ProjectViewProjectInfoCard.vue";
import ProjectViewProjectPlanCard from "./ProjectViewProjectPlanCard.vue";
import ProjectViewProjectTimelineCard from "./ProjectViewProjectTimelineCard.vue";
import ProjectViewProjectPermissionsCard from "./ProjectViewProjectPermissionsCard.vue";
import ExpenseList from '@/components/expenses/ExpenseList.vue';

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    ProjectViewProjectInfoCard,
    ProjectViewProjectPlanCard,
    ProjectViewProjectTimelineCard,
    ProjectViewProjectPermissionsCard,

    InvoiceList,
    ExpenseList,
  },
  setup() {
    const projectData = ref(null);
    const projectUpdated = ref(false);
    const projectId = computed(() => router.currentRoute.params.id )

    store
      .dispatch("project/fetchProject", { id: projectId.value })
      .then((response) => {
        projectData.value = response;
      })
      .catch((error) => {
          projectData.value = undefined;
      });

    return {
      projectData,
      projectUpdated,
      projectId,
    };
  },
};
</script>
