export const messages = {
  onApproved: {
    inputLabel: "Veuillez renseigner le montant validé",
    text: "Montant demandé:",
    confirmButtonText: "Oui, approuver",
  },
  onAccepted: {
    inputLabel: "Veuillez renseigner le montant à servir",
    text: "Montant accepté:",
    confirmButtonText: "Oui, accepter",
  },
  onRejected: {
    inputLabel: "Veuillez renseigner le motif de rejet",
    text: "Montant demandé:",
    confirmButtonText: "Oui, rejeter",
  },
};

export const _ = null;