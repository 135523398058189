import axios from "@axios";
import { messages } from "@/utils/alert-content";
import { amountToCurrency } from "@/utils/formatAmount";
export default {
  methods: {
    approveRequest(expense) {
      let self = this;
      let swal = this.$swal;

      let currentAmount = null;
      const paid = parseInt(expense.total_paid ?? 0);
      //default content
      let alertOptions = {
        inputLabel: "",
        text: "",
        confirmButtonText: "",
        status: "",
      };
      if (expense.status == "pending") {
        currentAmount = expense.amount;
        alertOptions = { ...messages.onApproved };
      } else if (expense.status == "approved") {
        currentAmount = expense.approved_amount ?? 0;
        alertOptions = {
          ...messages.onAccepted,
          inputLabel: `Montant déjà servit: ${amountToCurrency(paid)}`,
        };
      }

      swal
        .fire({
          title: "Cette action est irreversible. Êtes vous sûr?",
          input: "text",
          inputLabel: alertOptions.inputLabel,
          inputValue: currentAmount - paid,
          inputValidator: (value) => {
            if (!value) {
              return "Vous avez besoin de renseigner un montant";
            }
          },
          text: `${alertOptions.text} ${amountToCurrency(currentAmount)}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: alertOptions.confirmButtonText,
          cancelButtonText: "Annuler",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          preConfirm(validatedAmount) {
            const options = {
              headers: {
                Accept: "application/vnd.api+json",
                "Content-Type": "application/vnd.api+json",
              },
            };
            let requestData = {
              amount: expense.amount,
              status: expense.status,
            };
            if (expense.status == "pending") {
              requestData["approved_amount"] = parseInt(validatedAmount);
              requestData["status"] = "approved";
            } else if (expense.status == "approved") {
              requestData["paid"] = parseInt(validatedAmount);
              const currentPaidAmount =
                parseInt(validatedAmount) + parseInt(paid);
              if (parseInt(expense.approved_amount) == currentPaidAmount)
                requestData["status"] = "accepted";
              //set expense status to accepted and save fund history
              else if (currentPaidAmount < 0) {
                swal.showValidationMessage(
                  "Veuillez entrer un montant valide!"
                );
                return;
              } else if (
                currentPaidAmount > parseInt(expense.approved_amount)
              ) {
                swal.showValidationMessage(
                  "Le montant supérieur au montant accepté!"
                );
                return;
              }
            }
            // update a request make from fund history
            return axios
              .put(
                `finances/fund-histories/requests/${expense.id}`,
                requestData,
                options
              )
              .then(({ data, status }) => {
                if (status != 200) throw new Error(data.message);
                self.$emit("expenseUpdated");
                return data.resource;
              })
              .catch((error) => {
                swal.showValidationMessage(
                  "Une erreur est survenue lors de l'approbation."
                );
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal.fire({
              icon: "success",
              title: `${
                result.value.status == "approved" ? "Approuvée!" : "Payée!"
              }`,
              text: `La requête a été ${
                result.value.status == "approved" ? "approuvée" : "payée"
              } avec succès.`,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
    },
    rejectRequest(expense) {
      let self = this;
      let swal = this.$swal;

      const currentAmount = expense.amount;
      const alertOptions = { ...messages.onRejected };

      swal
        .fire({
          title: "Cette action est irreversible. Êtes vous sûr?",
          input: "text",
          inputLabel: `${alertOptions.text} ${currentAmount} XOF`,
          inputValue: '',
          inputValidator: (value) => {
            if (!value) {
              return "Veuillez rensigner un motif de rejet.";
            }
          },
          text: alertOptions.inputLabel,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: alertOptions.confirmButtonText,
          cancelButtonText: "Annuler",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
          preConfirm(validatedReason) {
            const options = {
              headers: {
                Accept: "application/vnd.api+json",
                "Content-Type": "application/vnd.api+json",
              },
            };
            let requestData = {
              amount: expense.amount,
              reason: validatedReason,
              status: 'rejected',
            };
            // update a request make from fund history
            return axios
              .put(
                `finances/fund-histories/requests/${expense.id}`,
                requestData,
                options
              )
              .then(({ data, status }) => {
                if (status != 200) throw new Error(data.message);
                self.$emit("expenseUpdated");
                return data.resource;
              })
              .catch((error) => {
                swal.showValidationMessage(
                  "Une erreur est survenue lors du rejet."
                );
              });
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            swal.fire({
              icon: "success",
              title: "Rejetée",
              text: `La requête a été rejetée avec succès.`,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        });
    },
    expenseHandled() {
      this.$emit("expenseUpdated");
    },
  },
}