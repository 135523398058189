import project from "@/store/modules/projects-module";
import axios from "@axios";
import { useRouter } from "@core/utils/utils";
import { computed } from "@vue/composition-api";
import Swal from "sweetalert2";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default function useProjectDetails(props, emit) {
  const { route, router } = useRouter();
  const toast = useToast();

  const PROECT_WON_STATUS = "REALISATION";
  const toggleAsLost = () => {
    Swal.fire({
      title: "Êtes vous sûr?",
      text: "Ce projet apparêtra désormais comme perdu!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, continuer!",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-outline-danger ml-1",
      },
      buttonsStyling: false,
      preConfirm() {
        const uid = props.projectData?.value.uid;
        return axios
          .put(`toggle/projects/${uid}/lost`, {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
            },
          })
          .then(({ data, status }) => {
            if (status != 200) throw new Error(data.message);
            return data.resource;
          })
          .catch((error) => {
            Swal.showValidationMessage(
              "Une erreur est survenue lors de la modification."
            );
          });
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Perdu!",
          text: "Ce projet est considéré comme perdu.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        }).then((result) => {
          router.go(-1);
        });
      }
    });
  };

  const closeProject = () => {
    let projectData = props.projectData.value
    let budgetState = projectData?.budget_state // get budget state from budget tracking with intention of determining if it was added
    let paymentReceived = projectData?.inventory.total_received // get project total payment

    if(budgetState != 'réel') {
      toast({
        component: ToastificationContent,
        props: {
          title: "Suivi Budget",
          text: 'Veuillez enregistrer le vendu et passez au suivi réel',
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
      return;
    }

    if( !paymentReceived || paymentReceived == 0) {
      toast({
        component: ToastificationContent,
        props: {
          title: "État de paiement",
          text: "Aucun paiement n'a été enregistré pour ce projet",
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
      return;
    }
    Swal.fire({
      title: "Ce projet disparaitra de la liste des projets !",
      text: "Assurez-vous que les factures ont été payé et les doocuements du projet sont archivés.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, continuer!",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-outline-primary ml-1",
      },
      buttonsStyling: false,
      preConfirm() {
        const uid = props.projectData?.value.uid;
        return axios
          .put(`toggle/projects/${uid}/close`, {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
            },
          })
          .then(({ data, status }) => {
            if (status != 200) throw new Error(data.message);
            return data.resource;
          })
          .catch((error) => {
            Swal.showValidationMessage(
              "Une erreur est survenue lors de la modificatioon du projet."
            );
          });
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Fermé!",
          text: "Le projet a été clôturé.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        }).then((result) => {
          router.go(-1);
        });
      }
    });
  };

  const hasProjectExpenses = computed(() => {
    let inventory = props.projectData.value?.inventory;
    if (typeof inventory === "object" && inventory.total_project_expenses)
      return true;
    return false;
  });

  const isWon = computed(() => {
    if (props.projectData.value?.status == PROECT_WON_STATUS) return true;
    return false;
  });

  const isLost = computed(() => {
    let inventory = props.projectData.value?.inventory;
    if (typeof inventory === "object" && inventory.set_as_lost == true)
      return true;
    return false;
  });

  const isClosed = computed(() => {
    let inventory = props.projectData.value?.inventory;
    if (typeof inventory === "object" && inventory.is_closed == true)
      return true;
    return false;
  });

  return {
    toggleAsLost,
    closeProject,
    isWon,
    isLost,
    isClosed,
    hasProjectExpenses,
  };
}
