<template>
  <b-card>
    <b-row>
      <!-- Project Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Project Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            button
            @click="pathToFileManager"
            variant="light"
            size="114px"
            rounded
          >
            <div class="row" :class="{ 'text-primary' : isWon, 'text-secondary' : isClosed, 'text-danger' : isLost }">
              <feather-icon
                class="col-12"
                icon="FolderIcon"
                height="45"
                width="45"
              />
              <span class="col-12">Archives</span>
            </div>
          </b-avatar>
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0" :class="{ 'text-primary' : isWon, 'text-secondary' : isClosed, 'text-danger' : isLost }" badge="4">
                {{ projectData.code }} <span v-if="isLost">(Perdu)</span><span v-else-if="isClosed">(Clôturé)</span>
              </h4>
              <div class="d-flex flex-column">
                <div class="card-text">
                  <span
                    >{{ projectData.details.concerned_department }} -
                    <i>{{ formatDate(projectData.created_at) }}</i></span
                  >
                  <span v-if="isLost" class="text-danger ml-1">
                    <feather-icon icon="AlertTriangleIcon" />
                  </span>
                </div>
                <span class="card-text"
                  >Responsable :
                  {{
                    projectData.assignee ? projectData.assignee.name : ""
                  }}</span
                >
              </div>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="$can('edit_project', 'Projets')"
                :to="{
                  name: 'projects-edit',
                  params: { id: projectData.uid },
                }"
                variant="primary"
              >
                Modifier
              </b-button>
              <b-button
                v-if="$can('delete_project', 'Projets') && !isClosed"
                variant="outline-danger"
                class="ml-1"
                @click="deleteProject"
              >
                Supprimer
              </b-button>
            </div>
          </div>
        </div>

        <!-- Task stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="RotateCwIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ projectData.task_pending }}</h5>
              <small>Tâches En Cours</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="CheckCircleIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">{{ projectData.task_done }}</h5>
              <small>Tâches Terminées</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="AlertCircleIcon" class="mr-75" />
              <span class="font-weight-bold">Statut</span>
            </th>
            <td
              class="pb-50 text-uppercase text-truncate"
              style="max-width: 60%"
            >
              {{ projectData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="AlertCircleIcon" class="mr-75" />
              <span class="font-weight-bold">Type de Projet</span>
            </th>
            <td
              class="pb-50 text-uppercase text-truncate"
              style="max-width: 60%"
            >
              <b-badge variant="light-primary text-capitalize">
                {{ projectData.type }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Client</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ projectData.customer.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UnderlineIcon" class="mr-75" />
              <span class="font-weight-bold">Bailleur</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ projectData.details.lessor }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CalendarIcon" class="mr-75" />
              <span class="font-weight-bold">Date de Dépôt</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ projectData.details.deposit_date }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CompassIcon" class="mr-75" />
              <span class="font-weight-bold">Lieu de Dépôt</span>
            </th>
            <td class="pb-50">
              {{ projectData.details.deposit_place }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon icon="AtSignIcon" class="mr-75" />
              <span class="font-weight-bold">Crée par</span>
            </th>
            <td>
              {{ projectData.creator.name }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import axios from "@axios";
import { useRouter } from "@core/utils/utils";
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
import moment from "moment";
import useProjectDetails from './useProjectDetails';
import { toRefs } from '@vue/composition-api';
import Swal from "sweetalert2";
moment.locale("fr");

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
  },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();
    const formatDate = (date) => moment(new Date(date).getTime()).fromNow();

    const { isWon, isLost, isClosed} = useProjectDetails(toRefs(props), emit)

    //path to project file manager
    const pathToFileManager = () => {
      const uid = props.projectData.folder.uid;
      router.push({ name: "folder-detail", params: { folder: uid } });
    };

    // delete project
    const deleteProject = () => {
      Swal.fire({
      title: "Êtes vous sûr?",
      text: "Ce projet sera supprimé et cette action est irréversible!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, supprimer !",
      cancelButtonText: "Annuler",
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-outline-primary ml-1",
      },
      buttonsStyling: false,
      preConfirm() {
        const uid = props.projectData?.uid;
        return axios
          .delete(`projects/${uid}`, {
            headers: {
              Accept: "application/vnd.api+json",
              "Content-Type": "application/vnd.api+json",
            },
          })
          .then(({ data, status }) => {
            if (status != 200) throw new Error(data.message);
            return data;
          })
          .catch((error) => {
            Swal.showValidationMessage(
              "Une erreur est survenue lors de la suppression du projet."
            );
          });
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          icon: "success",
          title: "Supprimé!",
          text: "Le projet a été supprimé.",
          customClass: {
            confirmButton: "btn btn-success",
          },
        }).then((result) => {
          router.push({ name: "projects-list" });;
        });
      }
    });
    }

    return {
      pathToFileManager,
      formatDate,
      isWon,
      isLost,
      isClosed,
      deleteProject,
    };
  },
};
</script>
