<template>
  <b-card
    v-if="
      $can('add_project_request', 'Projets') ||
      $can('track_budget', 'Projets') ||
      $can('track_payments', 'Projets')
    "
    no-body
    class="border-primary"
  >
    <b-card-body>
      <!-- expense button -->
      <expense-handler
        v-if="$can('add_project_request', 'Projets') && isWon && !isClosed"
        :projectId="projectData.uid"
        class="mb-2"
        @expenseAdded="expenseHandled"
      />
      <!-- budget tracking -->
      <b-button
        v-if="$can('track_budget', 'Projets')"
        :to="{
          name:
            projectData.budget_state == 'réel'
              ? 'tracking-real'
              : 'budget-trancking-add',
          params: { id: projectData.uid },
        }"
        class="mb-2"
        block
        variant="warning"
      >
        Suivi Budget
      </b-button>
      <!-- paiement status -->
      <b-button
        v-if="$can('track_payments', 'Projets') && !isClosed && isWon"
        :to="{
          name: 'project-payment-status',
          params: { id: projectData.uid },
        }"
        block
        variant="info"
      >
        État Paiements
      </b-button>
      <!-- define project state -->
      <b-button
      v-if="!isLost && !isWon && !isClosed"
        block
        variant="danger"
        @click="toggleAsLost"
      >
        Définir comme Perdu
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BBadge, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ExpenseHandler from "@/components/expenses/ExpenseHandler.vue";
import useProjectDetails from './useProjectDetails';
import { toRefs } from '@vue/composition-api';

export default {
  props: {
    projectData: {
      type: Object,
      required: true,
    },
    projectUpdated: Boolean,
  },
  components: {
    BCard,
    BCardBody,
    BButton,
    ExpenseHandler,
  },
  computed: {
    responsable() {
      return this.projectData.assignee?.name;
    },
  },
  methods: {
    expenseHandled() {
      this.$emit("projectUpdated");
    },
  },
  setup(props, { emit }) {
    const {
      toggleAsLost,
      isWon,
      isLost,
      isClosed,
      hasProjectExpenses,
    } = useProjectDetails(toRefs(props), emit)
    return {
      toggleAsLost,
      isWon,
      isLost,
      isClosed,
      hasProjectExpenses,
    };
  },
};
</script>

<style></style>
