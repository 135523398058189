<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Par page</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1">
      <b-form-group
        label="Trier"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">-- aucun --</option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
            class="w-25"
          >
            <option :value="false">Asc</option>
            <option :value="true">Desc</option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group
        label="Filtre"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Rechercher"
          />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Effacer
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        ref="expensesList"
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :tbody-tr-class="rowClass"
        :items="getProjectExpenses"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        :show-empty="true"
        :busy="isLoading"
        empty-text="Aucune entrée enregistrée"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <!-- Column: expense Status -->
        <template #cell(status)="data">
          <b-avatar
            :id="`expense-row-${data.item.id}`"
            size="32"
            :variant="`light-${
              resolveExpenseStatusVariantAndIcon(data.item.status).variant
            }`"
          >
            <feather-icon
              :icon="resolveExpenseStatusVariantAndIcon(data.item.status).icon"
            />
          </b-avatar>
          <b-tooltip
            v-if="data.item.status"
            :target="`expense-row-${data.item.id}`"
            placement="top"
          >
            <p class="mb-0">
              {{ resolveExpenseStatusVariantAndIcon(data.item.status).text }}
            </p>
            <!-- <p class="mb-0">
            Balance: {{ data.item.balance }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.dueDate }}
          </p> -->
          </b-tooltip>
        </template>
        <template #cell(type)="data">
          {{ data.item.type.toUpperCase() }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="
              data.item.status == 'pending' &&
              $can('add_project_request', 'Projets')
            "
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item variant="warning">
              <expense-handler
                :index="data.item.id"
                :projectId="projectId"
                class="d-inline"
                :btnFormat="true"
                :isDropdown="true"
                :requestData="data.item"
                @expenseUpdated="expenseHandled"
              />
            </b-dropdown-item>

            <b-dropdown-item variant="danger" @click="rejectRequest(data.item)">
              <feather-icon
                :id="`expense-row-${data.item.id}-reject-icon`"
                icon="XIcon"
                size="16"
              />
              Annuler
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>En cours...</strong>
          </div>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import axios from "@axios";
import expensesMixin from "@/mixins/expenses";
import {
  BTable,
  BAvatar,
  BSpinner,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BTooltip,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ExpenseHandler from "@/components/expenses/ExpenseHandler.vue";

export default {
  name: "ExpenseList",
  components: {
    BTable,
    BAvatar,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BTooltip,
    BDropdown,
    BDropdownItem,
    ExpenseHandler,
  },
  props: ["updated", "projectId"],
  directives: {
    Ripple,
  },
  mixins: [expensesMixin],
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      rowsCount: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      isLoading: false,
      fields: [
        {
          key: "id",
          label: "Id",
        },
        { key: "designation", label: "Désignation", sortable: true },
        {
          key: "status",
          label: "Statut",
        },
        { key: "amount", label: "Montant" },
        { key: "type", label: "Type Dépense", sortable: true },
        { key: "approved_amount", label: "Montant Approuvé", sortable: true },
        { key: "cumulative_amount", label: "Monstant Cumulé" },
        { key: "total_paid", label: "Reçu" },
        { key: "made_by", label: "Effectué Par", sortable: true },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  watch: {
    projectUpdated: function (newVal) {
      if (newVal == true) {
        this.$refs.expensesList.refresh();
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    totalRows: {
      // Set the initial number of items
      get() {
        return this.rowsCount;
      },
      set(val) {
        this.rowsCount = val;
      },
    },
    projectUpdated: {
      // Set the initial number of items
      get() {
        return this.updated;
      },
      set(val) {
        this.$emit("update:updated", val);
      },
    },
  },
  methods: {
    getProjectExpenses(ctx, callback) {
      this.isLoading = true;
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
        params: {
          filter: ctx.filter,
          perPage: ctx.perPage,
          page: ctx.currentPage,
          sortBy: ctx.sortBy,
          sortDesc: ctx.sortDesc,
        },
      };
      axios
        .get(`finances/projects/${this.projectId}/expenses`, options)
        .then(({ data }) => {
          callback(data.data);
          this.rowsCount = data.meta.total;
          this.isLoading = false;
        });
      this.projectUpdated = false;
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    rowClass(item, type) {
      const colorClass = "table-danger";
      if (!item || type !== "row") {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (!item.is_paid) {
        return colorClass;
      }
    },
    resolveExpenseStatusVariantAndIcon(status) {
      if (status === "pending")
        return { variant: "warning", icon: "PieChartIcon", text: "En cours" };
      if (status === "accepted")
        return {
          variant: "success",
          icon: "CheckCircleIcon",
          text: "Acceptée",
        };
      if (status === "approved")
        return { variant: "secondary", icon: "SaveIcon", text: "Approuvée" };
      if (status === "rejected")
        return { variant: "danger", icon: "InfoIcon", text: "Rejetée" };
      return { variant: "secondary", icon: "XIcon", text: "Inconnu" };
    },
  },
};
</script>
