var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$can('add_project_request', 'Projets') ||
    _vm.$can('track_budget', 'Projets') ||
    _vm.$can('track_payments', 'Projets')
  )?_c('b-card',{staticClass:"border-primary",attrs:{"no-body":""}},[_c('b-card-body',[(_vm.$can('add_project_request', 'Projets') && _vm.isWon && !_vm.isClosed)?_c('expense-handler',{staticClass:"mb-2",attrs:{"projectId":_vm.projectData.uid},on:{"expenseAdded":_vm.expenseHandled}}):_vm._e(),(_vm.$can('track_budget', 'Projets'))?_c('b-button',{staticClass:"mb-2",attrs:{"to":{
        name:
          _vm.projectData.budget_state == 'réel'
            ? 'tracking-real'
            : 'budget-trancking-add',
        params: { id: _vm.projectData.uid },
      },"block":"","variant":"warning"}},[_vm._v(" Suivi Budget ")]):_vm._e(),(_vm.$can('track_payments', 'Projets') && !_vm.isClosed && _vm.isWon)?_c('b-button',{attrs:{"to":{
        name: 'project-payment-status',
        params: { id: _vm.projectData.uid },
      },"block":"","variant":"info"}},[_vm._v(" État Paiements ")]):_vm._e(),(!_vm.isLost && !_vm.isWon && !_vm.isClosed)?_c('b-button',{attrs:{"block":"","variant":"danger"},on:{"click":_vm.toggleAsLost}},[_vm._v(" Définir comme Perdu ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }