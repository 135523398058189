<template>
  <div>
    <div v-if="btnFormat">
      <div
        v-if="isDropdown"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal="`modal-expense-${index}`"
        @show="setRequestProperties"
      >
        <feather-icon :id="`request-icon`" icon="EditIcon" size="16" />
        Modifier
      </div>
      <b-button
        v-else
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal="`modal-expense-${index}`"
        @show="setRequestProperties"
        variant="primary"
        block
      >
        Ajouter Requête
      </b-button>
    </div>
    <b-button
      v-else
      variant="flat-warning"
      class="btn-icon rounded-circle"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal="`modal-expense-${index}`"
      @show="setRequestProperties"
    >
      <feather-icon :id="`request-icon`" icon="EditIcon" size="16" />
    </b-button>
    <b-tooltip title="Modifier Requête" :target="`request-icon`" />

    <b-modal
      :id="'modal-expense-' + index"
      cancel-variant="outline-secondary"
      :ok-title="expense.id ? 'Modifier' : 'Enregistrer'"
      cancel-title="Annuler"
      centered
      :title="expense.id ? 'Modifier Requête' : 'Ajouter Requête'"
      @ok="onSubmit"
    >
      <validation-observer
        ref="refFormObserver"
        @submit.stop.prevent="onSubmit"
      >
        <b-form>
          <!-- designation -->
          <validation-provider
            #default="{ errors }"
            name="designation"
            rules="required"
          >
            <b-form-group label="Désignation" label-for="designation">
              <vue-autosuggest
                :suggestions="designationOptions"
                v-model="expense.designation"
                :input-props="inputProps"
                @selected="onSelectedDesignation"
                :limit="limit"
                @input="onDesignationChange"
              >
                <template slot-scope="{ suggestion }">
                  <div class="d-flex align-items-center">
                    <div class="detail">
                      <b-card-text>
                        {{ suggestion.item.designation }}
                      </b-card-text>
                    </div>
                  </div>
                </template>
              </vue-autosuggest>
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- amount -->
          <validation-provider
            #default="{ errors }"
            name="Montant"
            :rules="{ required, positive }"
          >
            <b-form-group label="Montant" label-for="amount">
              <b-form-input
                v-model="amount"
                min="0"
                id="amount"
                type="number"
                placeholder="Ex: 20000000"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <!-- due date -->
          <validation-provider
            #default="{ errors }"
            name="Prévu Pour"
            rules="required"
          >
            <b-form-group label="Prévu Pour" label-for="due_date">
              <flat-pickr
                id="due_date"
                v-model="expense.due_date"
                class="form-control"
              />

              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store";
import axios from "@axios";
import { mapGetters } from "vuex";

import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BCardText,
  BFormInvalidFeedback,
  BFormInput,
  BFormGroup,
  BTooltip,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";

import { required, alphaNum, positive } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import { onFieldChange } from "@/utils/filter";

export default {
  name: "ExpenseHandler",
  props: {
    index: {
      type: Number,
      default: 1,
    },
    projectId: {
      type: String,
      required: true,
    },
    btnFormat: {
      type: Boolean,
      default: true,
    },
    isDropdown: {
      type: Boolean,
      default: false,
    },
    requestData: {
      type: Object,
      default: () => ({
        id: null,
        designation: "",
        type: "project",
        due_date: null,
      }),
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    VueAutosuggest,
    BCardText,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormInvalidFeedback,
    BTooltip,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      positive,
      designationOptions: [],
      inputProps: {
        id: "autosuggest__input",
        class: "form-control",
        placeholder: "Entrer une désignation...",
      },
      limit: 10,
      amount: null,
      expense: JSON.parse(JSON.stringify(this.requestData)),
    };
  },
  watch: {
    amount: function (val) {
      this.expense.amount = parseInt(val);
    },
    // requestData: {
    //   handler: "setRequestProperties"
    // },
  },
  computed: {
    ...mapGetters("project", {
      expensesData: "expenses",
    }),
  },
  // created() {
  //   this.getExpensesList();
  // },
  methods: {
    getExpensesList() {
      const params = {
        projectId: this.projectId,
      };
      store.dispatch("project/fetchExpenses", params);
    },
    setRequestProperties() {
      this.getExpensesList();
      if (this.requestData.id) this.amount = parseInt(this.requestData.amount);
    },
    onSelectedDesignation(option) {
      this.expense.designation = option.item.designation;
    },
    onDesignationChange(text) {
      this.designationOptions = onFieldChange(
        text,
        this.expensesData,
        this.limit,
        "designation"
      );
    },
    onSubmit() {
      this.$refs.refFormObserver
        .validate()
        .then((success) => {
          if (success) {
            if (this.expense.id) {
              axios
                .put(
                  `finances/fund-histories/requests/${this.expense.id}`,
                  this.expense
                )
                .then((response) => {
                  this.$emit("expenseUpdated");
                });
            } else {
              delete this.expense.id;
              axios
                .post(
                  `finances/projects/${this.projectId}/expenses`,
                  this.expense
                )
                .then((response) => {
                  this.$emit("expenseAdded");
                });
            }
          }
        })
        .then(() => {
          this.expense = JSON.parse(JSON.stringify(this.requestData));
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
