/**
 * Use to filter input for autosuggest input and retur result
 * 
 * @param {string} input
 * @param {array} data
 * @param {number} limit
 * @param {string} limit nullable
 * 
 * @returns {array} filter of data based on input provided
 */
export const onFieldChange = (input, data, limit, field = null) => {
  if (input === '' || input === undefined) return [{
    data: [],
  }]

  let lookupField = 'name'
  if(field != null) lookupField = field
  // get customers list and loop through to get the customer according  to the input
  const filtered = data.filter(item => {
    return item[lookupField].toLowerCase().indexOf(input.toLowerCase()) > -1
  }).slice(0, limit)

  return [{
    data: filtered,
  }]
}

export const _ = null